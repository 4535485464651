import { Injectable } from '@angular/core'
import { EChartsOption } from 'echarts'
import { ApiService } from '../api/api.service'
import { merge, Observable, delay, switchMap, tap } from 'rxjs'
import { DistributionChart, PerDepartmentChart, ShoutlyChartsResponse } from '../../models/charts.model'
import { StoreService } from '../store/store.service'
import { ThemingService } from '../theming/theming.service'

export interface DashboardReport{
  name: string
  description?: string
  category: 'generic' | 'collab' | 'cost' | 'earn'
  chartType: 'distribution' | 'table' | 'pie'
  forOrg: string[],
  isRenderHeavy?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class ChartsService {
  constructor (
    private apiService: ApiService,
    private storeService: StoreService,
    private themingService: ThemingService
  ) { }

  dashboardReports: DashboardReport[] = [
    {
      name: 'summary',
      description: 'Summary of the current period',
      category: 'generic',
      chartType: 'table',
      forOrg: ['gigger', 'employer']
    },
    {
      name: 'collab_distribution',
      description: 'Collaboration distribution',
      category: 'collab',
      chartType: 'distribution',
      forOrg: ['gigger', 'employer']
    },
    {
      name: 'collab_reasons_for_assignment',
      description: 'Reasons for assignment',
      category: 'collab',
      chartType: 'table',
      forOrg: ['employer']
    },
    {
      name: 'collab_reason_distribution',
      description: 'Reason distribution',
      category: 'collab',
      chartType: 'distribution',
      forOrg: ['employer']
    },
    {
      name: 'collab_per_department',
      description: 'Collaboration per department',
      category: 'collab',
      chartType: 'pie',
      forOrg: ['employer']
    },
    {
      name: 'collab_most_demand_giggers',
      description: 'Most demanded giggers',
      category: 'collab',
      chartType: 'table',
      forOrg: ['employer']
    },
    {
      name: 'collab_ordering_users',
      description: 'Collaboration ordering users',
      category: 'collab',
      chartType: 'table',
      forOrg: ['employer']
    },
    {
      name: 'collab_most_active_employers',
      description: 'Most active employers',
      category: 'collab',
      chartType: 'table',
      forOrg: ['gigger']
    },
    {
      name: 'cost_collabs_distribution',
      description: 'Collaboration distribution',
      category: 'cost',
      chartType: 'distribution',
      forOrg: ['employer']
    },
    {
      name: 'cost_reasons_for_assignment',
      description: 'Reasons for assignment',
      category: 'cost',
      chartType: 'distribution',
      forOrg: ['employer']
    },
    {
      name: 'cost_reason_distribution',
      description: 'Reason distribution',
      category: 'cost',
      chartType: 'distribution',
      forOrg: ['employer']
    },
    {
      name: 'cost_giggers',
      description: 'Giggers',
      category: 'cost',
      chartType: 'table',
      forOrg: ['employer']
    },
    {
      name: 'cost_forecast',
      description: 'Cost forecast',
      category: 'cost',
      chartType: 'distribution',
      forOrg: ['employer'],
      isRenderHeavy: true
    },
    {
      name: 'cost_per_department',
      description: 'Cost per department',
      category: 'cost',
      chartType: 'pie',
      forOrg: ['employer']
    },
    {
      name: 'cost_ordering_users',
      description: 'Cost ordering users',
      category: 'cost',
      chartType: 'table',
      forOrg: ['employer']
    },
    {
      name: 'earn_distribution',
      description: 'Earn distribution',
      category: 'earn',
      chartType: 'distribution',
      forOrg: ['gigger']
    },
    {
      name: 'earn_forecast',
      description: 'Earn forecast',
      category: 'earn',
      chartType: 'distribution',
      forOrg: ['gigger'],
      isRenderHeavy: true
    },
    {
      name: 'earn_most_profitable_employers',
      description: 'Most profitable employers',
      category: 'earn',
      chartType: 'table',
      forOrg: ['gigger']
    }
  ]

  public getSummaryChart (period, reason = null, department = null, currency = null) {
    const reports = ['summary']
    return this.apiService.getReports(period, reports, reason, department, currency)
  }

  /**
   * External summary chart is based on salary calculations
   */
  public getExternalSummaryChart () {
    return this.apiService.getSummaryBasedInCollab()
  }

  getGiggerDashboardCharts (period, reason = null, department = null, currency = null) {
    const reports = this.dashboardReports.filter(report => report.forOrg.includes('gigger'))

    const initialLoadList = reports.filter(report => !report.isRenderHeavy).map(report => report.name)
    const heavyLoadList = reports.filter(report => report.isRenderHeavy).map(report => report.name)

    const initialLoad$: Observable<ShoutlyChartsResponse> = this.apiService.getReports(period, initialLoadList, reason, department, currency)
    const heavyLoad$: Observable<ShoutlyChartsResponse> = this.apiService.getReports(period, heavyLoadList, reason, department, currency)

    const res: Observable<ShoutlyChartsResponse> = merge(
      initialLoad$, heavyLoad$.pipe(delay(1000))
    )

    return res
  }

  getEmployerDashboardCharts (period, reason = null, department = null, currency = null): Observable<ShoutlyChartsResponse | any> {
    const reports = this.dashboardReports.filter(report => report.forOrg.includes('employer'))

    const initialLoadList = reports.filter(report => !report.isRenderHeavy).map(report => report.name)
    const heavyLoadList = reports.filter(report => report.isRenderHeavy).map(report => report.name)

    const initialLoad$: Observable<ShoutlyChartsResponse> = this.apiService.getReports(period, initialLoadList, reason, department, currency)
    const heavyLoad$: Observable<ShoutlyChartsResponse> = this.apiService.getReports(period, heavyLoadList, reason, department, currency)

    const res: Observable<ShoutlyChartsResponse> = merge(
      initialLoad$, heavyLoad$.pipe(delay(1000))
    )

    return res
  }

  public getDashboardCharts (period = 3, reason, department, currency): Observable<ShoutlyChartsResponse> {
    return this.storeService.organization$
      .pipe(
        tap(org => {
          if (!currency) currency = org.currency
        }),
        switchMap(org => {
          if (org.type === 'gigger') {
            return this.getGiggerDashboardCharts(period, reason, department, currency)
          }
          return this.getEmployerDashboardCharts(period, reason, department, currency)
        })
      )
  }

  assemblyBarChart (data: DistributionChart[], name = null, isForecast = false): EChartsOption {
    const xAxisData = []
    const seriesData = []

    if (data.every(item => item.value === 0)) {
      return null
    }

    data.forEach(item => {
      xAxisData.push(item.group)
      seriesData.push(item.value)
    })

    const chartOption: EChartsOption = {
      barWidth: '16px',
      grid: {
        containLabel: true
      },
      xAxis: {
        type: 'category',
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        nameTextStyle: {
          align: 'right'
        },
        data: xAxisData
      },
      yAxis: {
        type: 'value',
        splitNumber: 4,
        splitLine: {
          show: false
        },
        minInterval: 1,
        name
      },
      series: [
        {
          type: 'bar',
          data: seriesData,
          silent: true,
          itemStyle: {
            borderRadius: 100,
            borderColor: this.getMainColor(),
            borderType: [6, 8],
            borderWidth: isForecast ? 1 : 0
          }
        }
      ],
      color: isForecast ? this.getCurrentColorsLighter() : this.getCurrentColors()
    }
    return chartOption
  }

  assemblyPieChart (data: PerDepartmentChart, name): EChartsOption {
    const seriesData = []

    data.departments.forEach(item => {
      const name = item.group
      const value = item.value

      seriesData.push({ value, name })
    })

    const chartOption: EChartsOption = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: '5%',
        left: 'center'
      },
      series: [
        {
          name,
          type: 'pie',
          radius: ['40%', '70%'],
          label: {
            show: false
          },
          data: seriesData
        }
      ],
      color: this.getCurrentColors()
    }

    return chartOption
  }

  getCurrentColors (): string[] {
    return this.themingService.getCurrentTheme().colors
  }

  getCurrentColorsLighter (): string[] {
    const colorsBase: string[] = this.getCurrentColors()

    const res = colorsBase.map(color => color + '16')

    return res
  }

  getMainColor () {
    const colors = this.getCurrentColors()
    return colors[0]
  }
}
