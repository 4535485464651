import { Injectable } from '@angular/core'
import { FormGroup, ValidationErrors } from '@angular/forms'

@Injectable({
  providedIn: 'root'
})
export class FormErrorHandlerService {

  constructor() { }

  /** In case the error field doesn't exist, then return a generic error */
  public handleValidationErrors(form: FormGroup, validationErrors: ValidationErrors): string | null {
    if (!validationErrors) return null

    console.log('these are validationErrors', validationErrors)

    let firstMissingControlError: string | null = null

    Object.keys(validationErrors).forEach(field => {
      const control = form.get(field)
      if (control) {
        control.markAsTouched()
        control.setErrors({ serverError: validationErrors[field] })
      } else {
        // Store the message of the first error of the array if the control doesn't exist
        if (!firstMissingControlError) {
          firstMissingControlError = validationErrors[field][0]
        }
      }
    })

    form.updateValueAndValidity()

    // Return the error message of the first non-existent control or null if all controls exist
    return firstMissingControlError
  }
}