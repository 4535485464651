<div class="status-wrapper" *ngIf="_data">
    <div class="status" [class.loading]="isLoading" [ngClass]="getColorClass()">
      <ng-container *ngIf="isLoading">
        <span>{{ 'loading' | translate | ucfirst }}</span>
      </ng-container>
      <ng-container *ngIf="!isLoading">
        <span class="label-text">{{ _data.label | translate | ucfirst }}</span>
        <span *ngIf="_data.caption && !(_data.caption | translate).includes('caption')"
              [matTooltip]="_data.caption | translate | ucfirst"
              class="label-caption-trigger info material-icons-outlined">info</span>
      </ng-container>
    </div>
  </div>