<ng-container *ngIf="entity">
    <form [formGroup]="form">
      <div class="form-row">
        <mat-form-field class="form-field">
          <mat-label translate>Personal number</mat-label>
          <input 
            type="text" 
            id="entity_personal_number" 
            matInput 
            formControlName="personal_number" 
            placeholder="0000000000">
          <mat-icon 
            matSuffix 
            [matTooltip]="'validated' | translate"
            *ngIf="(entity.personal_number?.length > 4) && (form.get('personal_number').value === entity.personal_number && form.get('personal_number').valid)">
            <span class="material-icons-outlined">
              verified
            </span>
          </mat-icon>
          <mat-error>{{form.get('personal_number').errors?.serverError}}</mat-error>
        </mat-form-field>
        
        <ng-container *ngIf="sendOTPButtonConfig as conf">
          <button
            *ngIf="form.dirty"
            type="submit" 
            mat-stroked-button
            color="primary"
            [disabled]="isButtonDisabled"
            (click)="validate()"
            matPrefix
            >
            <mat-icon
            svgIcon="auth_bank_id">
            </mat-icon>
            <ng-container *ngIf="conf.isLoading">
              {{ ('loading' | translate | titlecase) + '...' }}
            </ng-container>
            <ng-container *ngIf="!conf.isLoading">
              {{ conf.disabledSecondsRemaining ? '(' + conf.disabledSecondsRemaining + 's)' : null }} {{ conf.label | translate }}
            </ng-container>

          </button>
        </ng-container>
      </div>
      <!-- <ng-container>
        {{
          form.get('personal_number').invalid +
          (form.get('personal_number').pristine | json) +
          form.get('personal_number').value
        }}
      </ng-container> -->
    </form>
  </ng-container>
  