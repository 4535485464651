import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import { User } from '../../models/user.model'
import { Subject, startWith, takeUntil } from 'rxjs'
import { FormErrorHandlerService } from '../../services/form-error-handler/form-error-handler.service'

@Component({
  selector: 'shared-user-name-form',
  templateUrl: './user-name-form.component.html',
  styleUrls: ['./user-name-form.component.scss']
})
export class UserNameFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() user: User
  @Input() validationErrors: ValidationErrors
  @Output() formUpdate = new EventEmitter<FormGroup>()

  public form: FormGroup
  private destroy$ = new Subject<void>()

  constructor(
    private formErrorHandlerService: FormErrorHandlerService
  ) {
    this.form = new FormGroup({
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required)
    })
  }

  ngOnInit(): void {
    if (this.user) {
      this.form.patchValue({
        first_name: this.user.first_name,
        last_name: this.user.last_name
      })
    }

    this.form.valueChanges
      .pipe(
        startWith(this.form.value),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.formUpdate.emit(this.form)
      })

    if (this.validationErrors) {
      this.formErrorHandlerService.handleValidationErrors(this.form, this.validationErrors)
    }

    this.formUpdate.emit(this.form)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.validationErrors && !changes.validationErrors.firstChange) {
      this.formErrorHandlerService.handleValidationErrors(this.form, changes.validationErrors.currentValue)
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
