import { ApiRequestBillCreate, Invoice, SelfInvoice, Wallet, WalletResponse } from '../../models/wallet.model'
import { PaginatedResponse } from '../../models/paginated.model'
import { Injectable } from '@angular/core'
import { ApiService } from '../api/api.service'
import { Observable, of, throwError, catchError, map, switchMap, take } from 'rxjs'
import { InvoiceListRequestPageParams } from '../../models/billing.model'
import { TableFilterTag } from '../../models/filters.model'
import * as lodash from 'lodash'
import { Params } from '@angular/router'
import { TableFiltersService } from '../table-filters/table-filters.service'
import { StoreService } from '../store/store.service'
import { HelpersService } from '../helpers/helpers.service'

@Injectable({
  providedIn: 'root'
})
export class InvoicingService {
  constructor(
    private apiService: ApiService,
    private helpersService: HelpersService,
    private storeService: StoreService,
    private tableFiltersService: TableFiltersService
  ) { }

  public getSelfInvoices(requestParams: Params): Observable<PaginatedResponse<SelfInvoice[]>> {
    const params = this.helpersService.convertParamsIntoHttpParams(requestParams)
    return this.apiService.getSelfInvoices(params)
  }

  public getSelfInvoicesFromCollab(collab, page, amount = 10): Observable<PaginatedResponse<SelfInvoice[]>> {
    return this.apiService.getSelfInvoicesFromCollab(collab, amount, page)
  }

  /**
   * Get wallet transactions based on the request params
   * @param requestParams
   * @returns
   */
  public getWallet(inputParams: Params): Observable<PaginatedResponse<Wallet[]>> {
    inputParams = { ...inputParams }
    const params = this.helpersService.convertParamsIntoHttpParams(inputParams, ['org_id'])

    return this.apiService.getWallet(params)
      .pipe(
        map(response => ({
          ...response, // Spread the existing properties
          data: this.mapInternalStatus(response.data) // Transform WalletResponse[] to Wallet[]
        })),
        switchMap(response => {
          // Assuming storeFiltrableElementsObservable returns an Observable
          return this.storeFiltrableElementsObservable(response.data)
            .pipe(
              map(() => response)
            )
        }),
        catchError(err => {
          console.error(err)
          return throwError(() => new Error('Error getting transactions list please try again later.'))
        })
      )
  }

  private mapInternalStatus(transactions: WalletResponse[]): Wallet[] {
    return transactions.map(transaction => {
      const internal_status = this.determineInternalStatus(transaction)
      return {
        ...transaction,
        internal_status: internal_status // Add internal_status
      }
    })
  }

  private determineInternalStatus(t: WalletResponse): string {
    if (this.isPaymentSent(t)) return 'payment-sent'
    if (!t.has_bill) return 'generating-bill'
    if (!this.isInvoicePaid(t)) return 'employer-pays-bill'
    return 'sending-payout'
  }

  private isInvoicePaid(t: WalletResponse): boolean {
    return t.is_gigger_transaction ? t.related_status === 'paid': t.paid
  }

  private isPaymentSent(t: WalletResponse): boolean {
    return t.is_gigger_transaction ? t.paid : t.related_status === 'paid'
  }

  public getInvoice(id): Observable<Invoice> {
    return this.apiService.getBill(id)
  }

  public getInvoices(requestParams: InvoiceListRequestPageParams): Observable<PaginatedResponse<Invoice[]>> {
    const params = this.helpersService.convertParamsIntoHttpParams(requestParams)
    return this.apiService.getBills(params)
  }

  public getInvoicesByCollab(collab_id, page): Observable<PaginatedResponse<Invoice[]>> {
    return this.apiService.getBillsByCollab(collab_id, page)
      .pipe(
        // convert timeline.event.amount into positive
        map(transactions => this.convertTimelineEventAmountIntoPositive(transactions))
      )
  }

  public getTransactionsFromCollab(collab_id, page): Observable<PaginatedResponse<WalletResponse[]>> {
    return this.apiService.getTransactionsFromCollab(collab_id, page)
      .pipe(
        map(page => {
          // convert element.amount and element.timeline.event.amount into positive
          page.data = this.roundValues(page.data)
          return page
        })
      )
  }

  public generateBill(transactions: ApiRequestBillCreate) {
    return this.apiService.generateBill(transactions)
  }

  public cancelBill(bill_id) {
    return this.apiService.cancelBill(bill_id)
  }

  public payBill(bill_id) {
    return of(bill_id + 'not implemented')
  }

  private roundValues(transactions: WalletResponse[]): WalletResponse[] {
    transactions?.forEach(bill => {
      bill.amount = Math.abs(bill.amount)
      bill.netto = Math.abs(bill.netto)
      bill.timeline?.forEach(event => {
        event.amount = Math.abs(event.amount)
      })
    })
    return transactions
  }

  private convertTimelineEventAmountIntoPositive(transactions: PaginatedResponse<Invoice[]>): PaginatedResponse<Invoice[]> {
    transactions.data?.forEach(bill => {
      bill.timeline?.forEach(event => {
        const res = event.amount = Math.abs(event.amount)
        return res
      })
    })

    return transactions
  }

  private storeFiltrableElementsObservable(collabReportTable: Wallet[]): Observable<TableFilterTag[]> {
    return this.storeService.organization$
      .pipe(
        take(1),
        map(organization => {
          if (!organization) {
            throw new Error('No organization available')
          }

          const counterpartOrgType: 'gigger' | 'employer' = organization.counterpart_type

          let res: TableFilterTag[] = collabReportTable.map(report => {
            if (!report[counterpartOrgType]) {
              return {
                id: '0',
                type: 'org_id',
                name: 'No org'
              }
            }

            return {
              ...report[counterpartOrgType],
              id: report[counterpartOrgType].id.toString(),
              type: 'org_id'
            }
          })

          res = lodash.uniqBy(res, 'id')

          this.tableFiltersService.addFiltrableOrg = res

          return res
        }),
        catchError(error => {
          console.error(error)
          return of([])
        })
      )
  }

  public addBalanceAmount(amount: string) {
    return of({})
  }
}
