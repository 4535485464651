import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'
import { EMPTY, Observable, Subject, map, of, startWith, takeUntil } from 'rxjs'
import { BaseCurrency, DataService } from '../../services/data/data.service'
import { LocationService, OpenStreeetMapNominatimGeocodingV1Response } from '../../services/location/location.service'
import { UploadService } from '../../services/upload/upload.service'
import { Upload } from '../../services/upload/upload'
import { OrgFormType, Organization } from '../../models/organization.model'
import { FormErrorHandlerService } from '../../services/form-error-handler/form-error-handler.service'
import { SettingsService } from '../../services/settings/settings.service'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'

@Component({
  selector: 'shared-org-form',
  templateUrl: './org-form.component.html',
  styleUrls: ['./org-form.component.scss']
})
export class OrgFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() org: Organization
  @Input() validationErrors: ValidationErrors = null
  @Input() currencies: BaseCurrency[] = []
  @Output() formUpdate = new EventEmitter<FormGroup>()

  constructor(
    public dataService: DataService,
    public settingsService: SettingsService,
    public locationService: LocationService,
    private uploadService: UploadService,
    private formErrorHandlerService: FormErrorHandlerService
  ) { }

  public avatarUpload$: Observable<Upload> = EMPTY
  private destroy$ = new Subject<void>()
  public genericError: string = null
  public currentOrg: number = null

  public form: FormGroup = new FormGroup({
    form: new FormControl('', Validators.required),
    currency: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    address1: new FormControl('', Validators.required),
    address2: new FormControl(''),
    city: new FormControl('', Validators.required),
    postcode: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
    vat_number: new FormControl(null),
    personal_number: new FormControl(null),
  })

  public countries: any[] = null
  public formList: OrgFormType[] = [
    {
      key: 'Business',
      value: 'Business',
      icon: 'business',
    },
    {
      key: 'Private',
      value: 'Private (Only Sweden)',
      icon: 'account_circle',
      onlyForCountries: ['SE'] // hide on countries =! sweden
    }
  ]

  ngOnInit(): void {
    // Patch the form with input data
    if (this.org) {
      this.form.patchValue(this.org)
    }

    this.getCurrencies()
    this.getCountries()

    this.currentOrg = this.settingsService.currentOrg

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        startWith(this.form.value),
        map(() => this.form)
      )
      .subscribe(form => this.formUpdate.emit(form))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.org && changes.org.currentValue) {
      this.patchPristineFields(changes.org.currentValue)
    }

    if (changes.validationErrors && changes.validationErrors.currentValue) {
      this.genericError = this.formErrorHandlerService.handleValidationErrors(this.form, changes.validationErrors.currentValue)
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  private getCurrencies() {
    this.form.disable()

    return this.settingsService.getOrgCurrencies()
      .subscribe({
        next: currencies => {
          this.currencies = currencies
        },
        error: () => { },
        complete: () => {
          this.form.enable()
        }
      })
  }

  private getCountries() {
    return this.dataService.fetchCountries()
      .subscribe({
        next: countries => {
          this.countries = countries
        }
      })
  }

  public getGeoLocation(event) {
    this.locationService.getGeoLocation()
      .subscribe(
        (info: OpenStreeetMapNominatimGeocodingV1Response) => this.patchLocationInfo(info)
      )

    event.stopPropagation()
  }

  private patchLocationInfo(info: OpenStreeetMapNominatimGeocodingV1Response) {
    this.form.patchValue({
      country: info?.address.country_code.toUpperCase(),
      city: info.address?.city ? info.address.city : info.address?.municipality,
      address1: info.address?.road,
      address2: info.address?.neighbourhood ? info.address?.neighbourhood + ', ' + info.address?.suburb : info.address?.suburb,
      postcode: info.address?.postcode
    })
  }

  public isOrgFormDisabled(onlyForCountries: string[]): boolean {
    const country: string = this.form.get('country').value

    let isDisabled = false

    if (onlyForCountries) {
      isDisabled = !onlyForCountries.includes(country)
    }
    return isDisabled
  }

  public onSubmitAvatar(file: File) {
    this.avatarUpload$ = this.uploadService.uploadOrganizationAvatar(file)
    return this.avatarUpload$
  }

  public onCountryChange(country: string): void {
    // if (country !== 'SE' && this.org.type === 'gigger') {
    //   this.form.get('form').reset()
    // }
  }

  public getSelectedFormIcon(key: string): string {
    const selectedForm = this.formList.find(form => form.key === key)
    return selectedForm ? selectedForm.icon : ''
  }

  public getSelectedFormValue(key: string): string {
    const selectedForm = this.formList.find(form => form.key === key)
    return selectedForm ? selectedForm.value : ''
  }

  private patchPristineFields(data: any): void {
    Object.keys(data).forEach(key => {
      const control = this.form.get(key)
      if (control && control.pristine) {
        control.patchValue(data[key])
      }
    })
  }
}
