/* Assemblied in top of https://www.npmjs.com/package/@finsweet/cookie-consent */
import { DOCUMENT } from '@angular/common'
import { AfterViewInit, Component, Inject, Renderer2 } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { PrivacyPreferencesDialogComponent } from '../../dialogs/privacy-preferences-dialog/privacy-preferences-dialog.component'

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements AfterViewInit {
  isScriptLoaded = false;
  preferences = {
    marketing: false,
    personalization: false,
    analytics: false
  };

  constructor(
    public dialog: MatDialog,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngAfterViewInit(): void {
    this.loadScript()
  }

  private loadScript() {
    const script = this.renderer.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = 'https://cdn.jsdelivr.net/npm/@finsweet/cookie-consent@1/fs-cc.js'
    script.setAttribute('fs-cc-mode', 'opt-in')
    // script.setAttribute('fs-cc-debug', 'true');

    this.renderer.appendChild(this.document.body, script)

    script.onload = () => {
      this.isScriptLoaded = true
      this.loadPreferences()
    }

    script.onerror = () => {
      console.error('An issue occurred during cookie management load.')
    }
  }

  private loadPreferences() {
    window['FsCC'] = window['FsCC'] || []
    window['FsCC'].push((FsCC) => {
      const consents = FsCC.store.getConsents()
      this.preferences = {
        marketing: consents.marketing,
        personalization: consents.personalization,
        analytics: consents.analytics
      }
      console.log('Loaded consents:', this.preferences)

      if (this.preferences.analytics) {
        this.dispatchAnalyticsActivatedEvent()
      }
    })
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(PrivacyPreferencesDialogComponent, {
      width: '480px',
      data: { preferences: this.preferences }
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.handleDialogClose(result)
      }
    })
  }

  handleDialogClose(result: any) {
    if (result.action === 'allow') {
      this.updateConsents({
        marketing: true,
        personalization: true,
        analytics: true
      })
    } else if (result.action === 'deny') {
      this.updateConsents({
        marketing: false,
        personalization: false,
        analytics: false
      })
    } else if (result.action === 'submit') {
      this.updateConsents(result.preferences)
    }
  }

  private updateConsents(consents: any) {
    this.preferences = consents // Update the local preferences

    window['FsCC'] = window['FsCC'] || []
    window['FsCC'].push((FsCC) => {
      FsCC.consentController.updateConsents(consents)
      console.log('Consents updated:', consents)

      if (consents.analytics) {
        this.dispatchAnalyticsActivatedEvent()
      }
    })
  }

  private dispatchAnalyticsActivatedEvent() {
    const event = new CustomEvent('analytics-activated', {
      detail: { message: 'Analytics consent granted.' }
    })
    window.dispatchEvent(event)
    console.log('Dispatched analytics-activated event')
  }
}
