import { Injectable } from '@angular/core'
import { WindowRefService } from '../window-ref/window-ref.service'

interface Window {
  hsConversationsSettings?: {
    loadImmediately: boolean
  }
  HubSpotConversations?: any
}

@Injectable({
  providedIn: 'root'
})
export class HubspotService {
  constructor() {}

  public async initHubspotSettings() {

    window['hsConversationsSettings'] = {
      loadImmediately: false
    }

    // alert('add widget hs')
    window['HubSpotConversations']?.widget?.load()
  }

  public async removeHubspotWidget() {
    window['HubSpotConversations']?.widget?.remove()
  }
}
