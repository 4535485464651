import { HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { Organization } from '../../models/organization.model'
import { User } from '../../models/user.model'
import { Upload, upload } from './upload'
import { ApiService } from '../api/api.service'
import { StoreService } from '../store/store.service'

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor (
    private storeService: StoreService,
    private apiService: ApiService
  ) { }

  uploadUserAvatar (file: File): Observable<Upload> {
    const data = new FormData()
    data.append('_method', 'PUT')
    data.append('avatar', file)
    return this.apiService.updateUserProfileFormData(data)
      .pipe(
        tap((data: HttpResponse<User>) => data.body ? this.storeService.cacheUserData(data.body) : null),
        upload()
      )
  }

  uploadOrganizationAvatar (file: File): Observable<Upload> {
    const org_id = this.storeService.getCachedUser().current_org
    const data = new FormData()
    data.append('_method', 'PUT')
    data.append('avatar', file)
    return this.apiService.updateOrganizationAvatar(data, org_id)
      .pipe(
        tap((data: HttpResponse<Organization>) => data.body ? this.storeService.cacheOrganization(data.body) : null),
        upload()
      )
  }
}
