import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Upload } from '../../services/upload/upload'

@Component({
  selector: 'shared-avatar-update',
  templateUrl: './avatar-update.component.html',
  styleUrls: ['./avatar-update.component.scss']
})
export class AvatarUpdateComponent {
  @Input() img: string
  @Input() load: Upload
  @Output() file = new EventEmitter()

  constructor () { }

  get hasImage (): boolean {
    // return false if img is null, undefined, empty string or contains "avatar-36.png"
    return !!this.img && !this.img.includes('avatar-36.png')
  }

  onFileInput (files: FileList | null): void {
    let file
    if (files) {
      file = files.item(0)
    }

    return this.emitFile(file)
  }

  emitFile (file: File) {
    return this.file.emit(file)
  }

  removeAvatar () {
    return this.file.emit('')
  }
}
