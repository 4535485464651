import { Component, Input, OnInit } from '@angular/core'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import { TranslateService } from '@ngx-translate/core'

export interface StatusChipConfig {
  slug: string
  label?: string
  caption?: string
  prefix?: string
  color?: 'red' | 'yellow' | 'orange' | 'green' | 'blue'
}

export const KNOWN_STATUSES: string[] = [
  // transaction
  _('status-transaction-bill-not-paid'),
  _('status-transaction-bill-not-paid-caption'),
  _('status-transaction-bill-paid'),
  _('status-transaction-bill-paid-caption'),
  _('status-transaction-not-billed'),
  _('status-transaction-not-billed-caption'),
  _('status-transaction-not-paid'),
  _('status-transaction-not-paid-caption'),
  _('status-transaction-no-selfinvoice'),
  _('status-transaction-no-selfinvoice-caption'),
  _('status-transaction-paid'),
  _('status-transaction-paid-caption'),
  _('status-transaction-paid-out'),
  _('status-transaction-paid-out-caption'),
  _('status-transaction-payment-in-progress'),
  _('status-transaction-payment-in-progress-caption'),
  _('status-transaction-pending-employer'),
  _('status-transaction-pending-employer-caption'),
  _('status-transaction-pending-gigger'),
  _('status-transaction-pending-gigger-caption'),
  _('status-transaction-unpaid'),
  _('status-transaction-unpaid-caption'),
  _('status-transaction-waiting-for-payout'),
  _('status-transaction-waiting-for-payout-caption'),
  // Internal statuses
  _('status-internal-generating-bill'),
  _('status-internal-generating-bill-caption'),
  _('status-internal-employer-pays-bill'),
  _('status-internal-employer-pays-bill-caption'),
  _('status-internal-sending-payout'),
  _('status-internal-sending-payout-caption'),
  _('status-internal-payment-sent'),
  _('status-internal-payment-sent-caption'),
  _('status-internal-undefined-status'),
  _('status-internal-undefined-status-caption'),

  // collab-report
  _('status-collab-report-new'),
  _('status-collab-report-accepted'),
  _('status-collab-report-declined'),
  _('status-collab-report-new-caption'),
  _('status-collab-report-accepted-caption'),
  _('status-collab-report-declined-caption'),

  // collab
  _('status-collab-sent'),
  _('status-collab-accepted'),
  _('status-collab-declined'),
  _('status-collab-cancelled'),
  _('status-collab-ongoing'),
  _('status-collab-finished'),
  _('status-collab-payout-waiting'),
  _('status-collab-sent-caption'),
  _('status-collab-accepted-caption'),
  _('status-collab-declined-caption'),
  _('status-collab-cancelled-caption'),
  _('status-collab-ongoing-caption'),
  _('status-collab-finished-caption'),
  _('status-collab-payout-waiting-caption'),

  // self-invoice
  _('status-self-invoice-paid'),
  _('status-self-invoice-paid-caption'),
  _('status-self-invoice-cancelled'),
  _('status-self-invoice-cancelled-caption'),
  _('status-self-invoice-not-paid'),
  _('status-self-invoice-not-paid-caption'),

  // invoice
  _('status-invoice-paid'),
  _('status-invoice-paid-caption'),
  _('status-invoice-not-paid'),
  _('status-invoice-not-paid-caption'),
  _('status-invoice-cancelled'),
  _('status-invoice-cancelled-caption'),

  // expenses
  _('status-expenses-new'),
  _('status-expenses-new-caption'),
  _('status-expenses-accepted'),
  _('status-expenses-accepted-caption'),
  _('status-expenses-declined'),
  _('status-expenses-declined-caption')
]

@Component({
  selector: 'shared-status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: ['./status-chip.component.scss']
})
export class StatusChipComponent implements OnInit{

  constructor(private translate: TranslateService){}

  ngOnInit(): void {
    console.log('Current language:', this.translate.currentLang);
    this.translate.get('status-collab-ongoing').subscribe((res: string) => {
      console.log('Translation:', res);  // Should log the translated value if key is found
    })
  }
  
  @Input() data: StatusChipConfig;
  @Input() isLoading: boolean = false;

  public get _data(): StatusChipConfig {
    if (!this.data) {
      return null;
    }
  
    // Normalize the slug by converting underscores to hyphens and lowercasing it
    const normalizedSlug = this.data.slug?.toLowerCase().replace(/_/g, '-');
    const label = this.data.label || `status-${this.data.prefix ? this.data.prefix + '-' : ''}${normalizedSlug}`;
    const caption = this.data.caption || `${label}-caption`;
  
    return {
      ...this.data,
      slug: normalizedSlug,
      label,
      caption
    };
  }

  public getColorClass(): string {
    // Return the color if specified, otherwise use the slug transformed to a class name
    return this.data.color || this._data.slug;
  }
}
